import flatten from 'lodash/flatten';
import { FurnitureConfig } from './types';
import { Option } from '../types';
import { screenFactor } from '../utils/configUtils';
import nyceConfig from './nyce-config';
import Utils from '../utils/utils';

const colors = {
  laminate: [
    //'white_u11209',
    //'light_grey_u12044',
    //'grey_u12091',
    //'graphite_u12233',
    'black_u12001',
    // 'burgundy_red_u17031',
    //'terracotta_u16166',
    //'taupe_u15133',
    //'yellow_u15579',
    //'mint_green_u19006',
    //'forest_green_u19008',
    //'petrol_green_u18006',
    //'blue_u18004',
  ],
  wood: ['oak', 'walnut'],
  steel: ['chrome', 'metal_stainless'],
};

const laminateMapping = {
  white_9003: 'white_u11209',
  black_9004: 'black_u12001',
  light_grey_7004: 'light_grey_u12044',
  grey_7037: 'grey_u12091',
  grey_7024: 'graphite_u12233',
  green_grey_7009: 'taupe_u15133',
  stone_grey_7030: 'taupe_u15133',
  light_yellow_1016: 'yellow_u15579',
  yellow_1021: 'yellow_u15579',
  red_3016: 'terracotta_u16166',
  violet_4009: 'terracotta_u16166',
  dark_red_3005: 'terracotta_u16166',
  dark_blue_5013: 'blue_u18004',
  blue_2_5000: 'petrol_green_u18006',
  blue_5009: 'petrol_green_u18006',
  mint_6033: 'mint_green_u19006',
  dark_green_6009: 'forest_green_u19008',
  green_6029: 'forest_green_u19008',
  // Removal of all lacquer color to black
  white_u11209: 'oak',
  light_grey_u12044: 'oak',
  grey_u12091: 'black_u12001',
  graphite_u12233: 'black_u12001',
  terracotta_u16166: 'walnut',
  taupe_u15133: 'oak',
  yellow_u15579: 'oak',
  mint_green_u19006: 'oak',
  forest_green_u19008: 'black_u12001',
  petrol_green_u18006: 'black_u12001',
  blue_u18004: 'black_u12001',
  // Mapping beech => oak
  beech: 'oak',
  // Mapping gold => steel
  matt_gold: 'chrome',
};

const lacquerMapping = {
  white_u11209: 'white_9003',
  black_u12001: 'black_9004',
};

const legsMapping = {
  straight: 'slanted',
  flat: 'corner',
  narrow: 'corner',
  hairpin: 'corner',
};

const legMaterials = {
  title: 'Leg colors',
  info: 'joyn_color_leg_info',
  iconName: 'materials/oak.png',
  colors: [
    {
      categoryName: 'wood',
      name: 'Oak',
      iconName: 'materials/oak.png',
      color: 'oak',
    },
    {
      categoryName: 'wood',
      name: 'Beech',
      iconName: 'materials/beech.png',
      color: 'beech',
    },
    {
      categoryName: 'wood',
      name: 'Walnut',
      iconName: 'materials/walnut.png',
      color: 'walnut',
    },
    {
      categoryName: 'varnish',
      name: 'White',
      iconName: 'materials/white_9003',
      color: 'white_9003',
    },
    {
      categoryName: 'varnish',
      name: 'Grey',
      iconName: 'materials/grey_7037',
      color: 'grey_7037',
    },
    {
      categoryName: 'varnish',
      name: 'Anthracite',
      iconName: 'materials/grey_7024',
      color: 'grey_7024',
    },
    {
      categoryName: 'varnish',
      name: 'Black',
      iconName: 'materials/black_9004',
      color: 'black_9004',
    },
    {
      categoryName: 'varnish',
      name: 'Yellow',
      iconName: 'materials/yellow_1021',
      color: 'yellow_1021',
    },
    {
      categoryName: 'varnish',
      name: 'Red',
      iconName: 'materials/red_3016',
      color: 'red_3016',
    },
    {
      categoryName: 'varnish',
      name: 'Blue',
      iconName: 'materials/blue_5009',
      color: 'blue_5009',
    },
    {
      categoryName: 'varnish',
      name: 'green_6029',
      iconName: 'materials/green_6029',
      color: 'green_6029',
    },
    {
      categoryName: 'varnish',
      name: 'light_grey_7004',
      iconName: 'materials/light_grey_7004',
      color: 'light_grey_7004',
    },
    {
      categoryName: 'varnish',
      name: 'stone_grey_7030',
      iconName: 'materials/stone_grey_7030',
      color: 'stone_grey_7030',
    },
    {
      categoryName: 'varnish',
      name: 'light_yellow_1016',
      iconName: 'materials/light_yellow_1016',
      color: 'light_yellow_1016',
    },
    {
      categoryName: 'varnish',
      name: 'violet_4009',
      iconName: 'materials/violet_4009',
      color: 'violet_4009',
    },
    {
      categoryName: 'varnish',
      name: 'mint_6033',
      iconName: 'materials/mint_6033',
      color: 'mint_6033',
    },
    {
      categoryName: 'varnish',
      name: 'blue_2_5000',
      iconName: 'materials/blue_2_5000',
      color: 'blue_2_5000',
    },
    {
      categoryName: 'varnish',
      name: 'green_grey_7009',
      iconName: 'materials/green_grey_7009',
      color: 'green_grey_7009',
    },
    {
      categoryName: 'varnish',
      name: 'dark_green_6009',
      iconName: 'materials/dark_green_6009',
      color: 'dark_green_6009',
    },
    {
      categoryName: 'varnish',
      name: 'dark_red_3005',
      iconName: 'materials/dark_red_3005',
      color: 'dark_red_3005',
    },
    {
      categoryName: 'varnish',
      name: 'dark_blue_5013',
      iconName: 'materials/dark_blue_5013',
      color: 'dark_blue_5013',
    },
    {
      categoryName: 'metal',
      name: 'chrome',
      iconName: 'materials/chrome.png',
      color: 'chrome',
    },
    {
      categoryName: 'metal',
      name: 'metal_stainless',
      iconName: 'materials/metal_stainless.png',
      color: 'metal_stainless',
    },
    {
      categoryName: 'metal',
      name: 'matt_gold',
      iconName: 'materials/matt_gold.png',
      color: 'matt_gold',
    },
  ],
};

const covers = {
  'Diamante Covers': [
    'diamante_white_12106',
    'diamante_beige_2530',
    'diamante_brown_4099',
    'diamante_brown_3995',
    'diamante_grey_2608',
    'diamante_grey_12108',
    'diamante_anthracite_4045',
    'diamante_beige_4104',
    'diamante_beige_12107',
    'diamante_blue_12104',
    'diamante_blue_2610',
    'diamante_blue_2535',
    'diamante_green_4909',
  ],
  'Brunei Covers': [
    'brunei_black_1',
    'brunei_grey_2',
    'brunei_grey_5',
    'brunei_beige_4',
    'brunei_beige_9',
    'brunei_brown_14',
    'brunei_azur_38',
    'brunei_azur_39',
    'brunei_azur_40',
    'brunei_blue_33',
    'brunei_pink_29',
    'brunei_pink_30',
    'brunei_pink_31',
    'brunei_salmon_20',
    'brunei_magenta_26',
    'brunei_purpule_24',
    'brunei_violet_28',
    'brunei_green_44',
    'brunei_green_45',
    'brunei_yellow_41',
  ],
  'Velvetex Covers': [
    'velvetex_anthracite_1002',
    'velvetex_grey_1000',
    'velvetex_grey_1042',
    'velvetex_grey_9115',
    'velvetex_green_104612',
    'velvetex_grey_101012',
    'velvetex_grey_101013',
    'velvetex_brown_2134',
    'velvetex_brown_9202',
    'velvetex_beige_2256',
    'velvetex_beige_2237',
    'velvetex_yellow_2272',
    'velvetex_violet_2255',
    'velvetex_pink_2315',
    'velvetex_pink_103637',
    'velvetex_red_2306',
    'velvetex_orange_103230',
    'velvetex_orange_104224',
    'velvetex_blue_2277',
    'velvetex_violet_104250',
    'velvetex_blue_1007',
    'velvetex_blue_104041',
    'velvetex_green_2253',
    'velvetex_green_9139',
    'velvetex_blue_2019',
    'velvetex_green_1375',
    'velvetex_green_210',
    'velvetex_orange_1090',
    'velvetex_blue_1203',
    'velvetex_blue_1372',
    'velvetex_violet_101',
    'velvetex_blue_1524',
    'velvetex_violet_1573',
  ],
  'Lech Covers': [
    'lech_lawa_6',
    'lech_lawa_7',
    'lech_lawa_11',
    'lech_sumatra_6',
    'lech_kreta_2',
    'lech_kreta_7',
    'lech_kreta_9',
    'lech_peru_1',
    'lech_peru_6',
    'lech_erie_1',
    'lech_erie_6',
    'lech_erie_8',
    'lech_erie_12',
    'lech_erie_15',
    'lech_erie_16',
  ],
  'Fine Fabric Covers': [
    'bahama_3',
    'bahama_8',
    'bahama_9',
    'bahama_30',
    'bahama_34',
    'oxford_9',
    'fargotex_river_1',
    'fargotex_river_5',
    'fargotex_river_6',
    'fargotex_river_10',
    'fargotex_river_11',
    'fargotex_river_12',
    'fargotex_river_13',
    'fargotex_river_14',
  ],
  belize: [
    'belize_23',
    'belize_25',
    'belize_27',
    'belize_32',
    'belize_64',
    'belize_74',
    'belize_561',
  ],
  zeppel: [
    'zepel_fenno_1',
    'zepel_fenno_3',
    'zepel_fenno_5',
    'zepel_fenno_6',
    'zepel_fenno_7',
    'zepel_fenno_8',
    'zepel_fenno_9',
    'zepel_fenno_12',
    'zepel_fenno_13',
    'zepel_fenno_15',
  ],
  kenia: ['kenia_taupe', 'kenia_walnut', 'kenia_brown', 'kenia_olive'],
  rancho: ['rancho_steel', 'rancho_nero'],
  toledo: ['toledo_2', 'toledo_4', 'toledo_6', 'toledo_15'],
  ribcord: [
    'ribcord_anthracite_67',
    'ribcord_chocolate_17',
    'ribcord_grey_180',
    'ribcord_onyx_169',
    'ribcord_petrol_56',
    'ribcord_taupe_12',
  ],
  scala: [
    'scala_anthracite_2',
    'scala_ash_8',
    'scala_chocolate_4',
    'scala_forest_13',
    'scala_lighter_green_12',
    'scala_mustard_16',
    'scala_navy_10',
    'scala_toffee_6',
    'scala_truffle_22',
    'scala_yellow_15',
  ],
  galaxy: [
    'galaxy_anthra_0074',
    'galaxy_dark_blue_0041',
    'galaxy_light_beige_0025',
    'galaxy_light_grey_0101',
    'galaxy_taupe_0056',
    'galaxy_olive_green_0062',
    'galaxy_jean_blue_0053',
    'galaxy_bramble_red_0063',
  ],
};

const baseCameraSetup = {
  i1: { angle: -30, vAngle: -15 },
  i2: { angle: -30, vAngle: -15 },
  i3: { angle: -30, vAngle: -15 },
  i4: { angle: -30, vAngle: -15 },
  L1: { angle: -45, vAngle: -35 },
  L2: { angle: 0, vAngle: -45 },
  L3: { angle: 0, vAngle: -45 },
  L4: { angle: 0, vAngle: -45 },
  U1: { angle: 0, vAngle: -45 },
  U2: { angle: 0, vAngle: -45 },
  U3: { angle: 0, vAngle: -45 },
  U4: { angle: 0, vAngle: -45 },
};

const feetCameraSetup = Utils.cloneDeep(baseCameraSetup);
const reviewCameraSetup = Utils.cloneDeep(baseCameraSetup);
Object.keys(feetCameraSetup).forEach(
  (pattern) => (feetCameraSetup[pattern].vAngle = 2)
);
Object.keys(reviewCameraSetup).forEach(
  (pattern) => (reviewCameraSetup[pattern].vAngle = -15)
);

/**
 * Joyn Sofa Config
 */
const config: SofaFurnitureConfig = {
  hasScene: false,
  scenesOptions: [],
  hasAssembly: true,
  hasViewInterior: false,
  showRelatedProduct: false,
  noBackgroundCarousel: false,
  translationDimensionMapping: true,
  defaultArmrestColor: 'baseAndBackrest',
  defaultArmrestLength: 120,
  default: {
    legs: {
      height: 'normal',
      type: 'square',
      color: 'oak',
    },
    modules: [
      {
        armrestLength: [0, 0],
        direction: 'none',
        extensionsLength: [0, 0],
        moduleLength: 800,
        type: 'base',
        sleepingMode: false,
        ottomanMode: false,
        storageMode: false,
        colors: {
          armrest: ['diamante_anthracite_4045', 'diamante_anthracite_4045'],
          backCushion: ['diamante_anthracite_4045', 'diamante_anthracite_4045'],
          cushion: ['diamante_anthracite_4045', 'diamante_anthracite_4045'],
        },
        // Joyn only
        color: {
          baseAndBackrest: 'diamante_anthracite_4045',
        },
      },
    ],
  },
  colors,
  laminateMapping,
  lacquerMapping,
  legsMapping,
  validColor: {
    leg: {
      square: ['laminate', 'wood'],
      slanted: ['laminate', 'wood'],
      frame: ['laminate', 'steel'],
      corner: ['laminate', 'steel'],
    },
  },
  nyce: {
    animation: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1200, 700, 133, 7.5, 203, 203),
      },
      processing: {
        noise: 0.05,
        samples: 400,
        no_intermediate: true,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    normal: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1500, 875, 166.25, 9.38, 253.75, 253.75),
      },
      processing: {
        noise: 0.05,
        samples: 280,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    mobile: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(800, 900, 50, 150, 50, 50),
      },
      processing: {
        noise: 0.05,
        samples: 280,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    hires: {
      camera: {
        fov: 40,
        angle: -30,
        vAngle: -15,
        coreAngle: 0,
        coreVAngle: -4,
        autozoom: true,
        bboxScalingFactor: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
        ...screenFactor(1440, 1440, 0, 0, 0, 0),
      },
      processing: {
        noise: 0.05,
        samples: 700,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    thumbnail: {
      camera: {
        height: 74,
        width: 74,
        fov: 40,
        angle: -30,
        vAngle: -15,
        coreAngle: 0,
        coreVAngle: -4,
        autozoom: true,
        bboxScalingFactor: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
        ...screenFactor(1440, 1440, 0, 0, 0, 0),
      },
      processing: {
        noise: 0.05,
        samples: 700,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
  },
  backUrl: '/couches-and-armchairs',
  maxSofaLength: 10000,
  moduleWithOneCushion: [800],
  endWithOneCushion: [1200],
  furnitureType: 'joyn',
  label: 'SVG',
  invalidUuidFallback: 'Q-AiOIo2X',
  stage: 'default', // TODO: delete
  tabs: {
    desktop: ['default'],
    mobile: ['modulesMobile', 'elementsMobile', 'colorsMobile', 'review'],
  },
  tabsNextText: ['To modules', 'To legs couch', 'To colors', 'To review'],
  // The following filter are to prevent technical specs from displaying on the website
  dimensionsFilters: [
    'length',
    'width',
    'height',
    'seat_height',
    'seat_depth',
    'seat_depth_extension',
  ],
  legsPositionParent: {
    armrest: {
      120: {
        close: { x: 50, y: 50 },
        far: { x: 95, y: 95 },
        gap: { x: 20, y: 10 },
      },
      240: {
        close: { x: 50, y: 50 },
        far: { x: 140, y: 95 },
        gap: { x: 30, y: 20 },
      },
    },
    base: {
      close: { x: 35, y: 35 },
      far: { x: 125, y: 80 },
      gap: { x: 20, y: 20 },
    },
    corner: {
      close: { x: 35, y: 35 },
      far: { x: 125, y: 80 },
      gap: { x: 20, y: 20 },
    },
  },
  legsPosition: {
    square: {
      position: 'close',
    },
    slanted: {
      position: 'far',
      offset: { x: 34.4, y: 34.4 },
    },
    frame: {
      position: 'close',
    },
    corner: {
      position: 'close',
    },
    support: {
      position: 'close',
    },
  },
  assemblyFeature: 7,
  productDetailText: 'About Our Joyn Sofa',
  forbiddenExtensionModuleLength: [],
  options: [
    // Leg options
    {
      action: 'update',
      group: 'feet',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'leg',
      text: 'Bricks',
      value: {
        type: 'square',
        height: 'normal',
      },
      iconName: 'configurator/joyn/legs-square',
    },
    {
      action: 'update',
      group: 'feet',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'leg',
      text: 'Slanted',
      value: {
        type: 'slanted',
        height: 'normal',
      },
      iconName: 'configurator/joyn/legs-slanted',
    },
    {
      action: 'update',
      group: 'feet',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'leg',
      text: 'Corner_Leg',
      value: {
        type: 'corner',
        height: 'normal',
      },
      iconName: 'configurator/joyn/legs-corner',
    },
    {
      action: 'update',
      group: 'feet',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'leg',
      text: 'Frame_Leg',
      value: {
        type: 'frame',
        height: 'normal',
      },
      iconName: 'configurator/joyn/legs-frame',
    },
    {
      action: 'update',
      group: 'feet',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'leg',
      text: 'Bricks',
      value: {
        type: 'square',
        height: 'high',
      },
      iconName: 'configurator/joyn/legs-square',
    },
    {
      action: 'update',
      group: 'feet',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'leg',
      text: 'Slanted',
      value: {
        type: 'slanted',
        height: 'high',
      },
      iconName: 'configurator/joyn/legs-slanted',
    },
    {
      action: 'update',
      group: 'feet',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'leg',
      text: 'Corner_Leg',
      value: {
        type: 'corner',
        height: 'high',
      },
      iconName: 'configurator/joyn/legs-corner',
    },
    {
      action: 'update',
      group: 'feet',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'leg',
      text: 'Frame_Leg',
      value: {
        type: 'frame',
        height: 'high',
      },
      iconName: 'configurator/joyn/legs-frame',
    },

    // Module options
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '80 cm',
      length: 800,
      iconName: 'configurator/joyn/module-800',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '120 cm',
      length: 1200,
      iconName: 'configurator/joyn/module-1200',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '160 cm',
      length: 1600,
      iconName: 'configurator/joyn/module-1600',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '200 cm',
      length: 2000,
      iconName: 'configurator/joyn/module-2000',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      sleepingMode: true,
      direction: 'none',
      text: 'Sleeping module',
      length: 2000,
      iconName: 'configurator/joyn/sofa-bed-2000',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'left',
      text: '120 cm',
      length: 1200,
      iconName: 'configurator/joyn/edge-left-1200',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'left',
      text: '160 cm',
      length: 1600,
      iconName: 'configurator/joyn/edge-left-1600',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'left',
      text: '200 cm',
      length: 2000,
      iconName: 'configurator/joyn/edge-left-2000',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'right',
      text: '120 cm',
      length: 1200,
      iconName: 'configurator/joyn/edge-right-1200',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'right',
      text: '160 cm',
      length: 1600,
      iconName: 'configurator/joyn/edge-right-1600',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'right',
      text: '200 cm',
      length: 2000,
      iconName: 'configurator/joyn/edge-right-2000',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'left',
      text: '12 cm',
      length: 120,
      iconName: 'configurator/joyn/armrest-120',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'left',
      text: '24 cm',
      length: 240,
      iconName: 'configurator/joyn/armrest-240',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'right',
      text: '12 cm',
      length: 120,
      iconName: 'configurator/joyn/armrest-120',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'right',
      text: '24 cm',
      length: 240,
      iconName: 'configurator/joyn/armrest-240',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      direction: 'none',
      text: '94 cm',
      length: 940,
      iconName: 'configurator/joyn/corner-940',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      text: 'To the left',
      length: 120,
      iconName: 'configurator/joyn/lounge-left',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      text: 'To the left',
      length: 240,
      iconName: 'configurator/joyn/lounge-left',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      text: 'To the right',
      length: 120,
      iconName: 'configurator/joyn/lounge-right',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      text: 'To the right',
      length: 240,
      iconName: 'configurator/joyn/lounge-right',
    },
    {
      action: 'remove',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'any',
      direction: 'none',
      text: 'Remove',
      length: 0,
      iconName: 'configurator/trash-bin',
    },
    // Leg color options
    ...[...colors.wood].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'leg',
        text: `${color.slice(0, 1).toUpperCase()}${color.slice(1)}`,
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: 'wood',
      };
    }),
    ...[...colors.laminate].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'leg',
        text: color,
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: 'laminate',
      };
    }),
    ...[...colors.steel].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'leg',
        text: color,
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: 'steel',
      };
    }),
    // Cover color options
    ...flatten(
      Object.keys(covers).map(
        (category: string): Option =>
          covers[category].map(
            (color: string): Option => ({
              action: 'update',
              group: 'color',
              isDisabled: null,
              isSelected: null,
              message: '',
              type: 'cover',
              value: color,
              iconName: '',
              text: '',
            })
          )
      )
    ),
  ],
  dimensions: {
    // TODO: delete
    armrestWidths: [120, 240],
    depth: 980, // normal base width
    depthWithExtension: 1620, // normal base + extension base width
  },
  defaultDimensions: {
    width: 0,
    length: 0,
    height: 0,

    center_length: 0,
    center_depth: 0,
    center_depth_withExt: 0,
    center_height: 0,

    left_length: 0,
    left_depth: 0,
    left_depth_withExt: 0,
    left_height: 0,

    right_length: 0,
    right_depth: 0,
    right_depth_withExt: 0,
    right_height: 0,
    seat_depth: 600,
    seat_depth_extension: 1240,
    seat_height: 420,
    high_seat_height: 480,

    seat_width: 600,
    seat_width_extension: 1240,
    corner_depth: 940,
    regular_depth: 980,
    pouf_depth: 600,
    extension_depth: 1620,
  },
  viewModes: {
    modules: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'module',
      groupStructure: 'feet',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    feet: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: 'feet',
      camera: feetCameraSetup,
      labelMode: '',
    },
    colorsWhole: {
      zoomMax: 1.8,
      subTabsName: [
        'colorsWhole',
        'colorsCushionBackcushion',
        'colorsBaseBackrest',
        'colorsLegs',
      ],
      iconName: 'configurator/joyn/color-all',
      selectionMode: 'all',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_whole',
      defaultSubTabName: 'colorsWhole',
      URLTabName: 'colors',
      text: 'Whole Couch',
    },
    colors: {
      zoomMax: 1.8,
      subTabsName: [
        'colors',
        'colorsWhole',
        'colorsCushionBackcushion',
        'colorsBaseBackrest',
        'colorsLegs',
      ],
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: baseCameraSetup,
      labelMode: '',
      defaultSubTabName: 'colors',
      URLTabName: 'colors',
      text: '',
    },
    colorsMobile: {
      zoomMax: 1.8,
      subTabsName: [
        'colorsWhole',
        'colorsCushionBackcushion',
        'colorsBaseBackrest',
        'colorsLegs',
      ],
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: baseCameraSetup,
      labelMode: '',
      defaultSubTabName: 'colorsWhole',
      URLTabName: 'colors',
      text: '',
    },
    colorsCushionBackcushion: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Cushions & Armrests',
      iconName: 'configurator/joyn/color-cushions',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_cushion_backcushion',
    },
    colorsBaseBackrest: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Base & Back',
      iconName: 'configurator/joyn/color-base',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_base_backrest',
    },
    colorsLegs: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Feet',
      iconName: 'configurator/joyn/color-legs',
      selectionMode: 'all',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_legs',
    },
    elements: {
      zoomMax: 1.8,
      subTabsName: ['modules', 'feet'],
      defaultSubTabName: 'modules',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
    review: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: reviewCameraSetup,
      labelMode: '',
    },
    default: {
      zoomMax: 1.8,
      camera: baseCameraSetup,
      group: 'color',
      groupStructure: '',
      iconName: '',
      labelMode: 'color_whole',
      selectionMode: 'all',
      resetSelection: true,
      subTabsName: ['modules', 'feet', 'colorsMobile'],
      text: '',
    },
    modulesMobile: {
      zoomMax: 1.8,
      subTabsName: ['seat-modules', 'lounge', 'corner-modules'],
      defaultSubTabName: 'seat-modules',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
    elementsMobile: {
      zoomMax: 1.8,
      subTabsName: ['armrests', 'Feet Height 11 cm', 'Feet Height 17 cm'],
      defaultSubTabName: 'armrests',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
    'seat-modules': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'module',
      groupStructure: 'feet',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    lounge: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'module',
      groupStructure: 'feet',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    'corner-modules': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'module',
      groupStructure: 'feet',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    armrests: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'module',
      groupStructure: 'feet',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    'Feet Height 11 cm': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: 'feet',
      camera: feetCameraSetup,
      labelMode: '',
    },
    'Feet Height 17 cm': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: 'feet',
      camera: feetCameraSetup,
      labelMode: '',
    },
  },
  notifications: {},
  covers,
  legMaterials,
  // components, which are define the primary colour
  // and weights to define priority (default weight is 1)
  primarySections: {
    cushion: 10,
    end_cushion_right: 10,
    armrest_left: 1,
    sofa_bed: 10,
    armrest_right: 1,
    corner_cushion: 10,
    pouf_cushion: 10,
    l_cushion_right: 10,
    end_cushion_left: 10,
    back_cushion: 1,
    l_cushion_left: 10,
  },
  basePoufLengthDiff: 0,
  basePoufWidthDiff: 20,
  poufWidthCM: 60,
  isOttoman: false,
  hadOttomanConfigurator: true,
  regularLegHeight: 11,
  highLegHeight: 17,
  defaultExtensionDepth: 640,
};

export interface SofaFurnitureConfig extends FurnitureConfig {
  defaultArmrestColor: string;
  defaultArmrestLength: number;
  defaultBackrestDepth?: number;
  endWithOneCushion: number[];
  forbiddenExtensionModuleLength: number[];
  maxSofaLength: number;
  moduleWithOneCushion: number[];
  covers?: any;
  colors: { [key: string]: string[] };
  laminateMapping: { [index: string]: string };
  lacquerMapping: { [index: string]: string };
  legsMapping?: { [index: string]: string };
  validColor?: {
    leg: { [index: string]: string[] };
  };
  legMaterials?: any;
  legsPosition?: any;
  legsPositionParent?: any;
  dimensions: any;
  primarySections: {
    [key: string]: number;
  };
  basePoufLengthDiff: number;
  basePoufWidthDiff: number;
  poufWidthCM: number;
  isOttoman: boolean;
  hadOttomanConfigurator: boolean;
  highLegHeight?: number;
  regularLegHeight?: number;
  defaultExtensionDepth?: number;
  // Flayr
  armrestHeightMapping?: any;
  backrestHeightMapping?: any;
  baseHeightMapping?: any;
  moduleHeightMapping?: any;
  backCushionHeight?: number;
  backRollCushionHeight?: number;
  seatingCushionHeight?: number;
  defaultColor?: string;
  validHeights?: any;
  validDepths?: any;
}

export default config;
